import { FarmPriceFetcherComponent } from 'components/PriceFetchers/FarmPriceFetcherComponent'
import { TokenPriceFetcherComponent } from 'components/PriceFetchers/TokenPriceFetcherComponent'
import FTM_TOKEN_LIST from 'constants/tokenLists/ftm_spooky_tokens.json'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { CoinGeckoPriceFetcher } from './CoinGeckoPriceFetcher'
import { GraphQLPriceFetcher } from './GraphQLPriceFetcher'

// Define refresh intervals in milliseconds for each fetcher
const TOKEN_REFRESH_INTERVAL_MS = 60 * 1000 // 1 minute for token prices
const COINGECKO_REFRESH_INTERVAL_MS = 60 * 1000 // 1 minute for CoinGecko prices
const FARM_REFRESH_INTERVAL_MS = 120 * 1000 // 2 minutes for farm prices
const GRAPH_QL_REFRESH_INTERVAL_MS = 60 * 1000

export default function PriceFetcherUpdater(): JSX.Element {
  const [showTokenPriceFetcher, setShowTokenPriceFetcher] = useState(false)
  const [showGraphQLPriceFetcher, setShowGraphQlPriceFetcher] = useState(false)
  const [showCoinGeckoPriceFetcher, setShowCoinGeckoPriceFetcher] = useState(false)
  const [showFarmPriceFetcher, setShowFarmPriceFetcher] = useState(false)

  const tokenAddresses = useMemo(() => {
    return FTM_TOKEN_LIST.tokens.map((token: any) => token.address)
  }, [])

  // Trigger the first fetch for each fetcher on mount
  useEffect(() => {
    setShowTokenPriceFetcher(true)
    setShowFarmPriceFetcher(true)
    setShowCoinGeckoPriceFetcher(true)
    setShowGraphQlPriceFetcher(true)
  }, [])

  // Handle token price fetching completion
  const handleTokenPriceFetchComplete = useCallback(() => {
    setShowTokenPriceFetcher(false)

    // Set a new timeout for the next token price fetch after completion
    setTimeout(() => setShowTokenPriceFetcher(true), TOKEN_REFRESH_INTERVAL_MS)
  }, [])

  // Handle CoinGecko price fetching completion
  const handleCoinGeckoPriceFetchComplete = useCallback(() => {
    setShowCoinGeckoPriceFetcher(false)

    // Set a new timeout for the next CoinGecko price fetch after completion
    setTimeout(() => setShowCoinGeckoPriceFetcher(true), COINGECKO_REFRESH_INTERVAL_MS)
  }, [])

  // Handle farm price fetching completion
  const handleFarmPriceFetchComplete = useCallback(() => {
    setShowFarmPriceFetcher(false)

    // Set a new timeout for the next farm price fetch after completion
    setTimeout(() => setShowFarmPriceFetcher(true), FARM_REFRESH_INTERVAL_MS)
  }, [])

  const handleGraphQLFetchComplete = useCallback(() => {
    setShowGraphQlPriceFetcher(false)

    // Set a new timeout for the next farm price fetch after completion
    setTimeout(() => setShowGraphQlPriceFetcher(true), GRAPH_QL_REFRESH_INTERVAL_MS)
  }, [])

  // Render fetcher components if they are visible
  return (
    <>
      {showTokenPriceFetcher && <TokenPriceFetcherComponent onFetchComplete={handleTokenPriceFetchComplete} />}
      {showFarmPriceFetcher && <FarmPriceFetcherComponent onFetchComplete={handleFarmPriceFetchComplete} />}
      {showCoinGeckoPriceFetcher && (
        <CoinGeckoPriceFetcher tokenAddresses={tokenAddresses} onFetchComplete={handleCoinGeckoPriceFetchComplete} />
      )}
      {showGraphQLPriceFetcher && <GraphQLPriceFetcher onFetchComplete={handleGraphQLFetchComplete} />}
    </>
  )
}
